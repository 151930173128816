var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-home" }, [
    _c(
      "div",
      {
        staticClass:
          "overview-section display__flex align_items__center justify_content__space_between"
      },
      [
        _c(
          "div",
          { staticClass: "left-view display__flex align_items__center" },
          [
            _c("div", [
              _vm._v("欢迎回来，" + _vm._s(_vm.userInfo.userName || ""))
            ]),
            _c("div", { staticClass: "left-item display__flex" }, [
              _c("div", [
                _vm._v("部门数 "),
                _vm.summary.deptNum !== undefined && _vm.summary.deptNum !== ""
                  ? _c("span", { staticStyle: { width: "48px" } }, [
                      _vm._v(_vm._s(_vm.summary.deptNum))
                    ])
                  : _c("span", [_vm._v(_vm._s(_vm._f("default_chars")("")))])
              ]),
              _c("div", [
                _vm._v("员工数 "),
                _vm.summary.userNum !== undefined && _vm.summary.userNum !== ""
                  ? _c("span", [_vm._v(_vm._s(_vm.summary.userNum))])
                  : _c("span", [_vm._v(_vm._s(_vm._f("default_chars")("")))])
              ])
            ])
          ]
        ),
        _c("div", { staticClass: "right-view" }, [
          _vm._v(_vm._s(_vm.dateDesc()))
        ])
      ]
    ),
    _vm.pageButtonPermission("hrms/home", "addDeptButton") ||
    _vm.pageButtonPermission("hrms/home", "addUserButton")
      ? _c("div", { staticClass: "panel-section" }, [
          _c("div", { staticClass: "panel-title section-stick" }, [
            _vm._v("快捷入口")
          ]),
          _c("div", { staticClass: "display__flex flex_wrap__wrap" }, [
            _vm.pageButtonPermission("hrms/home", "addUserButton")
              ? _c(
                  "div",
                  {
                    staticClass:
                      "panel-item panel-animat display__flex flex_direction__column align_items__center justify_content__center",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function($event) {
                        return _vm.goCreateOrg("create-user")
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/images/create-user.png")
                      }
                    }),
                    _c("div", [_vm._v("新增员工")])
                  ]
                )
              : _vm._e(),
            _vm.pageButtonPermission("hrms/home", "addDeptButton")
              ? _c(
                  "div",
                  {
                    staticClass:
                      "panel-item panel-animat display__flex flex_direction__column align_items__center justify_content__center",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function($event) {
                        return _vm.goCreateOrg("create-dept")
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/images/create-dept.png")
                      }
                    }),
                    _c("div", [_vm._v("新建部门")])
                  ]
                )
              : _vm._e()
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "panel-section" }, [
      _c("div", { staticClass: "panel-title section-stick" }, [
        _vm._v("已订购产品")
      ]),
      _c(
        "div",
        { staticClass: "display__flex flex_wrap__wrap" },
        _vm._l(_vm.purchaseList, function(item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass:
                "panel-item panel-animat display__flex flex_direction__column align_items__center justify_content__center"
            },
            [
              item.icon ? _c("img", { attrs: { src: item.icon } }) : _vm._e(),
              _c("div", [_vm._v(_vm._s(item.name))])
            ]
          )
        }),
        0
      )
    ]),
    _vm.pageButtonPermission("hrms/home", "nonuseProductButton")
      ? _c("div", { staticClass: "panel-section" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "display__flex flex_wrap__wrap" },
            _vm._l(_vm.unPurchaseList, function(item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass:
                    "panel-item disable display__flex flex_direction__column align_items__center justify_content__center"
                },
                [
                  item.icon
                    ? _c("img", { attrs: { src: item.icon } })
                    : _vm._e(),
                  _c("div", [_vm._v(_vm._s(item.name))])
                ]
              )
            }),
            0
          )
        ])
      : _vm._e(),
    _vm.pageLoading
      ? _c("div", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pageLoading,
              expression: "pageLoading"
            }
          ],
          staticClass: "loading-mask"
        })
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-title section-stick" }, [
      _vm._v("待启用产品 "),
      _c("span", { staticClass: "panel-subtitle" }, [
        _vm._v("(启用方式： 请拨打010-57835586)")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }